import React, { Component } from "react";

import SectionInside from "./SectionInside";
import MediaElement from "./MediaElement";
export default class Section extends Component {
  setTransition(id) {
    this.props.setTransition(id);
  }

  sectionInside = () => {
    return (
      <SectionInside
        setTransition={(id) => this.setTransition(id)}
        lang={this.props.lang}
        section={this.props.section}
        pos={this.props.pos}
      />
    );
  };

  render() {
    if (this.props?.section?.background?.mime_type === "video/mp4") {
      const sources = [
          {
            src: this.props.section.background.url,
            type: "video/mp4",
          },
        ],
        config = {
          startVolume: 0,
          loop: true,
          setDimensions: false,
          pauseOtherPlayers: false,
          clickToPlayPause: false,
        },
        tracks = {};
      return (
        <section
          className={`${this.props.section.section_width} section--${
            this.props.section.section_slug
          } ${
            this.props.section.special_section
              ? "specialSection--" + this.props.section.special_section
              : ""
          }`}
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="video">
            <MediaElement
              className="videoContainer"
              id="player2"
              mediaType="video"
              preload="none"
              width="180"
              height="160"
              muted
              loop={true}
              autoplay={true}
              // poster={poster}
              objectfit="cover"
              sources={JSON.stringify(sources)}
              options={JSON.stringify(config)}
              tracks={JSON.stringify(tracks)}
              playsInline={true}
            />
          </div>
          {this.sectionInside()}
        </section>
      );
    } else {
      //  console.log(this.props?.section?.background)
      return (
        <section
          className={`${this.props.section.section_width} section--${
            this.props.section.section_slug
          } ${
            this.props.section.special_section
              ? "specialSection--" + this.props.section.special_section
              : ""
          }`}
          style={{
            backgroundImage: `url(${this.props?.section?.background?.url})`,
          }}
        >
          {this.sectionInside()}
        </section>
      );
    }
  }
}
