import React, { Component } from "react";
import {
    CSSTransition,
  
    SwitchTransition,
  } from "react-transition-group";
  
export default class Hamburger extends Component {
  setMenuState = () => {
    this.props.setMenuState();
  };
  render() {
    return (
      <>
        <button
          className={`hamburger hamburger--3dx${
            this.props.isMenuOpen ? " is-active" : ""
          }`}
          type="button"
          onClick={this.setMenuState}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
    
            <CSSTransition
                in={this.props.isMenuOpen}
              timeout={400}
              unmountOnExit
              classNames="animation"
            >
              <div className="mobile-menu">{this.props.children}</div>
            </CSSTransition>
    
      </>
    );
  }
}
