import React, { Component } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Developments from "../Views/Developments";
import Bubbles from "../Views/Bubbles";

export default class SectionInside extends Component {
  createContentInside = (content) => {
    const columnCount = this.props.section?.content
      ? this.props.section.content.length
      : "1";
    const img = this.props.section.content[content]?.image?.sizes?.large;
    return (
      <div
        className={`section--column section--column__${columnCount} ${
          img ? "section--column__has-image" : ""
        }`}
      >
        {img ? (
          <img className="section--image" src={img} alt="this is nice" />
        ) : (
          ""
        )}
        {parse(this.props.section.content[content].column)}
      </div>
    );
  };

  createContent = () => {
    return (
      <div
        className={`section--content section--content__${this.props.section.content_position} text__color-${this.props?.section?.content_color}`}
      >
        {Object.keys(this.props?.section?.content).map((column, i) => {
          return this.createContentInside(column);
        })}
        {this.props.section.special_section !== false &&
        this.props.section.special_section !== ""
          ? this.createSpecialSection()
          : ""}
      </div>
    );
  };

  createTitle = () => {
    return (
      <div
        className={`section--title text__color-${this.props?.section?.title_color}`}
      >
        <h2>{parse(this.props.section.title)}</h2>
      </div>
    );
  };
  createButtonInside = (type = "") => {
    return (
      <button className={`arrow arrow__${this.props.section.content_position}`}>
        <span className="arrow--text">{this.props.section.button_forward}</span>
        <span className="arrow--line">
          <img
            className="arrow--head"
            src="/images/arrowHead.svg"
            alt="arrow"
          />
        </span>
      </button>
    );
  };
  setTransition(id) {
    this.props.setTransition(id);
  }
  createButton = () => {
    if (this.props.section?.button_page) {
      const url = this.props.section?.button_page?.split("/");
      const slug = url.slice(-2)[0];
      return (
        <Link
          onClick={() => this.setTransition(this.createId())}
          to={`/${
            this.props.lang
          }/${slug}/${this.props.section.button_section.toLowerCase()}`} //#${this.createId()}
        >
          {this.createButtonInside()}
        </Link>
      );
    } else {
      return this.createButtonInside();
    }
  };

  createSpecialSection = () => {
    const { lang } = this.props;
    if (this.props.section.special_section === "developments") {
      return <Developments language={lang} />;
    }
    if (this.props.section.special_section === "bubbles") {
      return <Bubbles pos={this.props.pos} language={lang} />;
    }
  };

  createId() {
    return this.props.section?.section_slug
      ? this.props.section?.section_slug.toLowerCase()
      : this.props.section.title
      ? this.props.section.title.toLowerCase()
      : "";
  }

  render() {
    return (
      <>
        <div className="section--inside" id={this.createId()}>
          {this.props.section.title && this.props.section.show_title
            ? this.createTitle()
            : ""}
          {this.props.section.content ? this.createContent() : ""}
        </div>
        {this.props.section?.button_forward !== "" ? this.createButton() : ""}
      </>
    );
  }
}
