import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default class LanguagePicker extends Component {
  constructor(props) {
    super(props);
    this.state = { selectionVisible: false };
  }

  //http://elon.localhost/lang

   componentDidMount() {
    
  }
  setLang = (lang) => {
    //console.log("picked -> ", lang)
    if (this.props.currentLang !== lang) {
      this.props.setLang(lang);
    }
  };
  createLangButton = (lang, index) => {
    const { languages } = this.props;
    return (
      <Link
        key={index}
        className={`lang${
          this.props.currentLang === lang ? " is-current" : ""
        }`}
        to={`/${lang}/`}
        onClick={() => this.setLang(languages[lang].code)}
      >
        {lang}
      </Link>
    );
  };
  languages = () => {
    const { isSmallScreen, languages  } = this.props;
    return Object.keys(languages).map((lang, index) => {
      //console.log(languages);

      return isSmallScreen ? (
        <li>{this.createLangButton(lang, index)}</li>
      ) : (
        this.createLangButton(lang, index)
      );
    });
  };

  showSelection = (val) => {
    this.setState((prevState) => ({
      selectionVisible: val ? val : !prevState.selectionVisible,
    }));
  };
  render() {
    const { selectionVisible } = this.state;
    const { isScrolled, currentLang, isSmallScreen } = this.props;
    if (isSmallScreen) {
      return <div className="languages">{this.languages()}</div>;
    } else {
      return (
        <>
          <div
            className={`languagePicker${
              isScrolled ? " languagePicker-scrolled" : ""
            }${selectionVisible ? " is-active" : ""}`}
            onMouseEnter={() => this.showSelection(true)}
            onMouseLeave={() => this.showSelection(false)}
            onClick={() => this.showSelection(false)}
          >
            <img
              className="bubbles"
              alt="bubbles"
              src={`${process.env.PUBLIC_URL}/images/lang${
                isScrolled ? "_blue" : ""
              }.svg`}
            />
            <span>{currentLang ? currentLang : "EST"}</span>
            <img
              className="arrow"
              alt="arrow"
              src={`${process.env.PUBLIC_URL}/images/arrow_down_2${
                isScrolled ? "_blue" : ""
              }.svg`}
            />
            {selectionVisible && (
              <div
                className={`dropdown${
                  selectionVisible ? " dropdown-active" : ""
                }`}
              >
                {this.languages()}
              </div>
            )}
          </div>
        </>
      );
    }
  }
}
