import React, { Component } from "react";
import { isMobile, isSafari } from "react-device-detect";

import Hamburger from "../Components/Hamburger";
import Section from "../Components/Section";
import Menu from "../Components/Menu";

export default class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: null,
      theposition: 0,
      currentPage: 1,
      currentPageName: "visioon",
      language:  this.props.match?.params?.lang?.toLowerCase() || "et",
      transition: false,
    };
  }

  componentDidMount() {
    !isMobile && window.addEventListener("wheel", this.listenToScroll);
    this.getPages();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params !== this.props.match.params) {
      if (this.state.transition) {
        setTimeout(() => {
          this.setPageAndPosIfHasAlready();
        }, 500);
      } else {
        this.setPageAndPosIfHasAlready();
      }
    }
  }
  setPageAndPosIfHasAlready() {
    const { match } = this.props;
    if (match?.params?.lang?.toLowerCase() !== this.state.language) {
      if (
        match?.params?.lang?.toLowerCase() === "et" ||
        match?.params?.lang?.toLowerCase() === "en"
      ) {
        this.setState({
          language: match.params.lang.toLowerCase(),
        });
      }
    }
    if (match?.params?.page) {
      let pageID = this.getPageID(match?.params?.page);
      if (pageID) {
        this.setState(
          {
            currentPage: pageID,
          },
          () => this.setPosAfterPage()
        );
      }
    }
  }

  setPosAfterPage() {
    const { match } = this.props;
    if (match?.params?.section) {
      this.setPosition(match?.params?.section);
    }
  }

  componentWillUnmount() {
    !isMobile && window.removeEventListener("wheel", this.listenToScroll);
  }

  listenToScroll = (event) => {
    let xCord = this.state.theposition - event.deltaY * 0.6;

    let currentContent =
      document.getElementById("asd").scrollWidth - window.innerWidth;
    if (xCord <= -currentContent) {
      xCord = -currentContent;
    } else if (xCord >= 0) {
      xCord = 0;
    }

    this.setState({
      theposition: xCord,
    });
  };

  async getPages() {
    const { language } = this.state;
    try {
      const fetchUrl =
        language === "et"
          ? "https://elon.ee/wp/wp-json/api/pages?lang=et"
          : "https://elon.ee/wp/wp-json/api/pages?lang=" + language;
      const response = await fetch(
        `${fetchUrl}` // ${getPageContent}${this.getLang()}
      );
      const pages = await response.json();

      this.setState({ pages }, () => this.setPageAndPosIfHasAlready());
    } catch (error) {
      console.log(error);
    }
  }

  setTransition(id) {
    if (id === "last") {
      this.setState({ transition: true });
    }
  }

  createSections = () => {
    const { pages, currentPage } = this.state;

    if (pages[currentPage]?.sections.length > 0) {
      return pages[currentPage].sections.map((section, i) => {
        return (
          <Section
            setTransition={(id) => this.setTransition(id)}
            lang={this.state.language}
            key={i}
            section={section}
            pos={this.state.theposition}
          />
        );
      });
    }
  };

  createTopBar = () => {
    return (
      <div className="top-bar">
        <a
          href={`${process.env.REACT_APP_WEB_URL}`}
          alt="Elon logo"
          className="logo"
        >
          {this.state.isMenuOpen && !isMobile ? (
            <img src="/images/elon_logo_blue.png" alt="ELON logo blue" />
          ) : (
            <img src="/images/elon_logo.png" alt="ELON logo white" />
          )}
        </a>
        <Hamburger
          isMenuOpen={this.state.isMenuOpen}
          setMenuState={this.setMenuState}
        />
      </div>
    );
  };

  setBodyClass() {
    if (this.state.isMenuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }
  removeBodyClass() {
    document.body.classList.remove("menu-open");
  }
  setMenuState = () => {
    this.setState(
      (prevState) => ({ isMenuOpen: !prevState.isMenuOpen }),
      () => this.setBodyClass()
    );
  };

  closeMenu = () => {
    this.setState({ isMenuOpen: false });
  };

  setPage = (pageID) => {
    this.closeMenu();
    this.setBodyClass();
  };

  getPageID = (pageName) => {
    const { pages } = this.state;
    let setPageID = null;

    Object.keys(pages).map((page) => {
      if (pages[page].post_name === pageName) {
        setPageID = page;
      }
    });

    return setPageID !== null ? setPageID : false;
  };

  setPageAndPosition = (page, title) => {
    const { language } = this.state;
    this.removeBodyClass();
    if (page !== this.state.currentPage) {
      const id = this.getPageID(page);

      this.setState(
        {
          currentPage: id,
        },
        () => this.setPosition(title)
      );
    } else {
      this.setPosition(title);
    }

    window.history.replaceState(
      null,
      page,
      `/${language}/${page.toLowerCase()}/${title.toLowerCase()}`
    );
  };

  setPosition(title) {
    let elementToLookFor = document?.getElementById(title.toLowerCase());

    if (elementToLookFor) {
      if (isMobile) {
        setTimeout(() => {
          const el = document?.getElementsByClassName(
            "section--" + title.toLowerCase()
          );

          if (el[0]) {
            elementToLookFor = el[0];
          }

          window.scrollTo({
            top: elementToLookFor.getBoundingClientRect().top,
            left: 0,
            behavior: "smooth",
          });
        }, 400);
      } else {
        this.setState({
          theposition: -elementToLookFor.offsetLeft,
        });
      }
    }

    this.closeMenu();
  }

  createTransition = () => {
    setTimeout(() => {
      this.setState({ transition: false });
    }, 2000);
    return <div className="transitionLayer"></div>;
  };

  render() {
    const { pages } = this.state;

    return (
      <>
        {this.state.transition ? this.createTransition() : ""}
        {this.createTopBar()}
        {pages && (
          <Menu
            isMenuOpen={this.state.isMenuOpen}
            pages={pages}
            setPageAndPosition={this.setPageAndPosition}
            language={this.state.language}
          />
        )}
        {isMobile ? (
          <div id="asd" className={`wrapper`}>
            {this.state.pages && this.state.currentPage
              ? this.createSections()
              : "Loading..."}
          </div>
        ) : (
          <>
            {this.state.pages && this.state.currentPage ? (
              <div
                id="asd"
                className={`wrapper ${isSafari ? " wrapper--safari" : ""}`}
                style={{
                  transform: `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${this.state.theposition}, 0, 0, 1)`,
                }}
              >
                {this.createSections()}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }
}
