import React, { Component } from "react";
import { isMobile } from "react-device-detect";

export default class Bubbles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bubble1: 0,
    };
  }
  setBubbleMovement(item, amount, windowWidth, elPos) {
    let trans = (windowWidth * elPos) / amount;
    if (trans < -40) {
      trans = -40;
    } else if (trans > 100) {
      trans = 100;
    }
    this.setState({
      [item]: trans,
    });
  }
  componentDidUpdate(prevProps) {
    const { pos } = this.props;
    if (pos !== prevProps.pos) {
      let elPos = document
        .getElementsByClassName("specialSection--bubbles")[0]
        .getBoundingClientRect().left;
      let windowWidth = window.innerWidth;

      this.setBubbleMovement("bubble1", 90000, windowWidth, elPos);
      this.setBubbleMovement("bubble2", 50000, windowWidth, elPos);
      this.setBubbleMovement("bubble3", 70000, windowWidth, elPos);
    }
  }

  render() {  
    const {language} = this.props;
    if (isMobile) {
      if(language === "et"){
        return <img src="/images/pallid_mob.jpg" alt="Bubbles" />;
      }else{
        return <img src="/images/pallid_mob_eng.jpg" alt="Bubbles" />;
      }
    } else {
      const img1 = "/images/bubbles/elon_parallax_01_highlights_sphere1.png";
      const img2 = "/images/bubbles/elon_parallax_01_highlights_sphere2.png";
      const img3 = "/images/bubbles/elon_parallax_01_highlights_sphere3.png";

      return (
        <div className="bubbles">
          <div
            id="bubble1"
            className="bubble bubble--first"
            style={{
              backgroundImage: `url(${img1})`,
              transform: `translate(${this.state.bubble1}%,-20%)`,
            }}
          >
            <p>
           {language === "et" ? "" : (<>Total value of<br /></>)}
              <span className="bubble--text__big">350</span>
              {language === "et" ? (<>m+ EUR <br /> väärtuses</>) : (<><br /> m+ EUR</>)}
            </p>
          </div>
          <div
            className="bubble bubble--second"
            style={{
              backgroundImage: `url(${img2})`,
              transform: `translate(${this.state.bubble2}%,20%)`,
            }}
          >
            <p>
              <span className="bubble--text__big">150 000</span>
               {language === "et" ? (<><br />+ m&sup2;</>) : ""}
              {language === "et" ? " Elu ja büroopindu" : (<><br />sqm of residential and <br />commercial property</>)}
            </p>
          </div>
          <div
            className="bubble bubble--third"
            style={{
              backgroundImage: `url(${img3})`,
              transform: `translate(${this.state.bubble3}%,-20%)`,
            }}
          >
            <p>
              <span className="bubble--text__big">20</span> 
              {language === "et" ? "aastat" : ""}
               <br />
              {language === "et" ? "kogemusi" : (<>years of <br />experience</>)}
            </p>
          </div>
        </div>
      );
    }
  }
}
