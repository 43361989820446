import React, { Component } from "react";
import LanguagePicker from "./LanguagePicker";

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { menu: null };
  }
  componentDidMount() {
    this.getMenuOrder();
  }
  async getMenuOrder() {
    const { language } = this.props;
    try {
      //https://elon.ee/wp/wp-json/acf/v3/options

      const fetchUrl =
        language === "et"
          ? "https://elon.ee/wp/wp-json/acf/v3/options/options_structure?lang=et"
          : "https://elon.ee/wp/wp-json/acf/v3/options/options_structure?lang=" +
            language;
      const response = await fetch(
        `${fetchUrl}` // ${getPageContent}${this.getLang()}
      );

      const menu = await response.json();
      /*
acf: {
menu_structure: 
*/
      this.setState({ menu: menu.acf.menu_structure });
    } catch (error) {
      //console.log(error);
    }
  }
  createMenuItems() {
    const { pages, language } = this.props;
    const { menu } = this.state;
    return Object.keys(menu).map((menuItem) => {
      return Object.keys(pages).map((page) => {
        const ps = pages[page].sections;
        const pageName = pages[page].post_name;
        return Object.keys(ps).map((section) => {
          if (
            ps[section].shown_in_menu &&
            (ps[section]?.title || ps[section]?.section_slug)
          ) {
            const value = ps[section]?.section_slug
              ? ps[section]?.section_slug.toLowerCase()
              : ps[section]?.title.toLowerCase();
            //   console.log(value, menu[menuItem]?.menu_item);
            if (value === menu[menuItem]?.menu_item) {
              return (
                <li onClick={() => this.goToPagePos(pageName, value)}>
                  {  language === "et" ? value : this.translateMenuItem(value)}
                </li>
              );
            }
          }
        });
      });
    });
  }
  translateMenuItem(value) {
    switch (value) {
      case "visioon":
        return "vision";
      case "meist":
        return "what we do";
      case "väärtused":
        return "values";
      case "teenused":
        return "services";
      case "arendused":
        return "developments";
      case "inimesed":
        return "Our people";
      case "kontakt":
        return "Contact";
      default:
        return "";
    }
  }
  goToPagePos(page, title) {
    const { pages } = this.props;
    // console.log("goto->", pages[0].ID, title);
    //this.props.setPage(pages[0].ID);
    this.props.setPageAndPosition(page, title);
  }

  render() {
    return (
      <div
        className={`menu ${this.props.isMenuOpen ? "is-active" : ""}`}
        style={{ backgroundImage: `url(${"/images/pall.jpg"})` }}
      >
        <ul>{this.state.menu !== null && this.createMenuItems()}</ul>
        <div className="languages">
          <ul>
            <li>
              <a href="https://elon.ee/et/visioon/visioon">ET</a>
            </li>
            <li>
              <a href="https://elon.ee/en/visioon/visioon">EN</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
