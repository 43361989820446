import React, { Component } from "react";

export default class Developments extends Component {
  constructor(props) {
    super(props);
    this.state = { devs: null };
  }
  componentDidMount() {
    this.getDevelopments();
  }
  async getDevelopments() {
    const { language } = this.props;
    try {
      //https://elon.ee/wp/wp-json/acf/v3/options

      const fetchUrl =
        language === "et"
          ? "https://elon.ee/wp/wp-json/acf/v3/options/options_developments?lang=et"
          : "https://elon.ee/wp/wp-json/acf/v3/options/options_developments?lang=" +language;
      const response = await fetch(
        `${fetchUrl}` // ${getPageContent}${this.getLang()}
      );
    

      const devs = await response.json();

      this.setState({ devs: devs.acf.developments });
    } catch (error) {
      console.log(error);
    }
  }

  createDiscription = (dev) =>{
    const { language } = this.props;
   
    if( language === "et"){
      return dev.description_et ? "- "+dev.description_et : "";
    }else{
      return dev.description_en ? "- "+dev.description_en : "";
    }
    

  }

  createDevelopments = () => {
    const { devs } = this.state;

    return (
      <>
        {Object.keys(devs).map((dev, i) => {
          return (
            <div className={`section--column section--column__`}>
              <div className="development">
                <div className="development--description">
                  <h3>{devs[dev].title} <span>{this.createDiscription(devs[dev])}</span></h3>
                  <a href={devs[dev].url}>{devs[dev].webpage}</a>
                </div>
                <div className="development--image">
                  <img
                    src={devs[dev].image.sizes.large}
                    alt={`${devs[dev].title}`}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  render() {
    return this.state.devs !== null && this.createDevelopments();
  }
}
