import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Master from "./Views/Master";
import "./App2.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => <Master {...props} />}>
            {" "}
            <Redirect to="/et/visioon/visioon" />
          </Route>
          <Route exact path="/:lang" render={(props) => <Master {...props} />}>
            <Redirect to="/:lang/visioon/visioon" />
          </Route>
          <Route
            exact
            path="/:lang/:page"
            render={(props) => <Master {...props} />}
          ></Route>
          <Route
            exact
            path="/:lang/:page/:section"
            render={(props) => <Master {...props} />}
          ></Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
